@import '../settings/settings.scss';

body,
html,
div#root {
    overflow: auto;
    height: 100%;
    padding-bottom: 0;
    background-color: $WHITE1;
    font-family: $poppinsRegular;
    color: $BLUE
}
@media print {
    body,
    html,
    div#root {
        height: auto;
    }
    #home-page-vide-container{
        height: 700px;
    }

}
.cookie-consent-buttonWrapper {
    text-align: center;
}
.CookieConsent {
    bottom: 15px !important
}

.imprintContainer{
    font-family: "Poppins Regular" !important;
    p:nth-child(1) {
        margin: 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 30px;
        font-weight: 600;
        color: rgb(0, 45, 89);
    }
    p:not(:nth-of-type(1)):not(:nth-of-type(10)), ul {
        font-size: 15px;
        font-weight: 400;
    }
    ul {
        list-style: none;
        margin: 0px;
        padding: 8px 0px;
        position: relative;
        li {
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            padding: 8px 16px;
            display: list-item;
        }
    }
}
.termsAndConditionsContainer{
    font-family: "Poppins Regular" !important;
    h2:nth-child(1){
        font-weight: 600;
        margin: 0px;
        font-size: 30px;
        line-height: 1.5;
        color: rgb(0, 45, 89);
        letter-spacing: -0.00833em;
        font-family: "Poppins Regular";
    }

    p:nth-child(2), p:nth-of-type(55), p:nth-of-type(85) {
        margin: 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 25px;
        text-align: center;
        font-weight: 600;
        padding-top: 33px;
    }
    p:not(:nth-of-type(1)):not(:nth-of-type(55)):not(:nth-of-type(85)){
        font-size: 15px;
        color: rgb(0, 45, 89);
        font-weight: 100;
    }
}
.privacyPolicyContainer{
    font-family: "Poppins Regular" !important;
    p:nth-child(1) {
        margin: 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 30px;
        font-weight: 600;
        color: rgb(0, 45, 89);
    }
    h2:nth-child(2), h2:nth-child(20), h2:nth-child(26), h2:nth-child(52), h2:nth-child(71) {
        margin: 15px 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 30px;
        color: rgb(0, 45, 89);
        font-weight: 600;
        padding-top: 12px;
    }
    h3 {
        margin: 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 18px;
        color: rgb(0, 45, 89);
        position: relative;
        font-weight: 600;
        top: 3px;
    }
    p:not(:nth-of-type(1)) {
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 15px;
        color: rgb(0, 45, 89);
        position: relative;
    }
    p:nth-child(21), p:nth-child(24){
        margin: 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-family: "Poppins Regular";
        font-size: 18px;
        color: rgb(0, 45, 89);
        position: relative;
        font-weight: 600;
    }
    ul {
        margin: 0px 0px 0px 38px;
        padding: 8px 0px;
        position: relative;
        list-style: disc;

        li {
            -webkit-box-pack: start;
            justify-content: flex-start;
            -webkit-box-align: center;
            align-items: center;
            position: relative;
            text-decoration: none;
            width: 100%;
            box-sizing: border-box;
            text-align: left;
            padding: 8px 16px;
            display: list-item;
        }
    }
}

// html::-webkit-scrollbar {
//     width: 5px;
//     height: 8px;
//     background-color: #aaa;
//     /* or add it to the track */
// }

// html::-webkit-scrollbar-thumb {
//     background: #000;
// }

a {
    text-decoration: none;
    color: $BLUE
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}


/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $CYAN;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// ::-webkit-media-controls {
//     display:none !important;
// }

.react-pdf__Page__canvas {
    height: 100% !important;
    width: 100% !important;
}


@media print {
    .client-contract,
    .client-contract * {
        visibility: visible;
    }

    .client-contract {
        position: absolute;
        left: 0;
        top: 0;
    }
}
.MuiChartsTooltip-root {
    z-index: 999
}

// .gradient-container {
//     position: relative;
//     width: 100%;
//     // max-width: 800px; /* Optional: set max width for the container */
//     margin: 0 auto; /* Center container */
//     padding: 20px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

// .gradient-container::before,
// .gradient-container::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   width: 50%; /* Width of the gradient on each side */
//   height: 100%;
//   z-index: 0; /* Positioned behind the content */
// }

// .gradient-container::before {
//   left: 0;
//   position: fixed;
//   background: linear-gradient(to right, rgba(255, 250, 244, 1), transparent); /* Left gradient */
// }

// .gradient-container::after {
//   right: 0;
//   position: fixed;
//   background: linear-gradient(to left, rgba(255, 250, 244, 1), transparent); /* Right gradient */
// }