@font-face {
  font-family: 'Poppins Black';
  src: local('Poppins Black'), local('Poppins-Black'),
    url('../assets/fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Regular';
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('../assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins ExtraBold';
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
    url('../assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: bolder;
}

@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins Light'), local('Poppins-Light'),
    url('../assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'EuphoriaScript-Regular';
  src: local('EuphoriaScript-Regular'), local('EuphoriaScript-Regular'),
    url('../assets/fonts/EuphoriaScript-Regular.ttf') format('truetype');
}




@font-face {
  font-family: 'Avenir Black';
  src: local('Avenir Black'), local('Avenir-Black'),
    url('../assets/fonts/AvenirLTStd-Black.otf') format('otf');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Avenir Book';
  src: local('Avenir Book'), local('Avenir-Book'),
    url('../assets/fonts/Avenir Book.ttf') format('truetype'),
    url('../assets/fonts/AvenirLTStd-Book.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova'), local('Proxima-Nova'),
    url('../assets/fonts/Proxima-Nova-ExCn-Xbold.otf') format('otf');
}


@font-face {
  font-family: 'ProximaNova Black';
  src: local('ProximaNova Black'), local('ProximaNova-Black'),
    url('../assets/fonts/ProximaNova-Black.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova-Black.woff') format('woff'),
    url('../assets/fonts/ProximaNova-Black.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaT Thin';
  src: local('ProximaNovaT Thin'), local('ProximaNovaT-Thin'),
    url('../assets/fonts/ProximaNovaT-Thin.woff2') format('woff2'),
    url('../assets/fonts/ProximaNovaT-Thin.woff') format('woff'),
    url('../assets/fonts/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova Regular';
  src: local('ProximaNova Regular'), local('ProximaNova-Regular'),
    url('../assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../assets/fonts/ProximaNova-Regular.woff') format('woff'),
    url('../assets/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
// @font-face {
//   font-family: 'Calibre Semibold';
//   src: local('Calibre Semibold'), local('Calibre-Semibold'),
//     url('../../assets/fonts/Calibre-Semibold.woff2') format('woff2'),
//     url('../../assets/fonts/Calibre-Semibold.woff') format('woff'),
//     url('../../assets/fonts/Calibre-Semibold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Calibre Bold';
//   src: local('Calibre Bold'), local('Calibre-Bold'),
//     url('../../assets/fonts/Calibre-Bold.woff2') format('woff2'), url('../../assets/fonts/Calibre-Bold.woff') format('woff'),
//     url('../../assets/fonts/Calibre-Bold.ttf') format('truetype');
//   font-weight: bold;
//   font-style: normal;
// }