@import "../../settings/settings.scss";

.list-box {
    // border-radius:60px !important;
    border-radius: $radius-rounded !important;
    width: $regular-box-width;
    height: $regular-box-height;
    margin-top:$regular-margin !important;
    text-transform: $regular-text !important;
    background-color: $GREEN !important;
    color:$BLACK !important;
    font-size: $regular-font !important;
    
}

.search_text{
    width:"1020px";
    height:"60px"
}
