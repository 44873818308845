@import '../../settings/settings.scss';
.container {
    display: flex;
    align-items: center;
    background-color: $WHITE1;
    flex-direction: column;
    height: inherit;
    margin: 0 auto;
    
}

.login_title {
    font-size: 35px;
    color: $CYAN;
    font-weight: 600;
}

.password_container {
    margin-top: 10px;
}

.forgot_passowrd {
    padding-top: 16px !important;
    color: $BLUE !important;
    font-size: 13px !important;
    text-decoration-color: $BLUE !important;
}

.social_login {
    border-radius: rem(60px);
    margin-top: 47px;
    text-transform: capitalize !important;
    background-color: #bcddd2;
    color: #323232;
    margin-left: 10px;
    font-size: 15px;
    width: 236px;
    height: 56px;
}

.sign_in {
    text-transform: capitalize !important;
    font-size: 18px;
    border-radius: rem(60px) !important;
    margin-top: 47px;
    width: 211px;
    height: 62px;
    background-color: #1c256e !important;
    color: rgba(255, 255, 255, 1);
}

.sign_up {
    margin-top: 42px;
    margin-bottom: 54px ;
    color: $BLUE !important;
    font-size: 15px !important;
    font-family: $poppinsRegular !important;
}

.sign_up_link {
    color: $BLUE !important;
    font-family: $poppinsRegular !important;
    text-decoration-color: $BLUE !important;
}