@import '../../settings/settings.scss';

.headerimages {
    height: rem(165px);
    border-radius: 80px;
    &--first_image {
        max-width: rem(249px);
        max-height: rem(165px);
    }
    &--common_image {
        max-width: rem(309px);
        max-height: rem(165px);
        
    }
    &--last_image {
        max-width: rem(249px);
        max-height: rem(165px);
    }
}