@import '../../settings/settings.scss';

.candidate-dashboard {
    &--application_status_chip {
        font-size: rem(18px);
        font-weight: 600;
        color: $BLUE;
        background-color: $CYAN1;
    }
    &--job_application_section {
        margin-top: rem(57px);
        &---jobs {
            display: flex;
            margin-top:rem(46px)
        }
       
    }
    &--pending_invites_scroll_container {
        @media (max-width: 599px) {
            display: block !important;
        }
    }
}